<template>
  <div class="right-content">
    <Breadcrumb
      :crumb="crumb"
      addpath="/product/add"
      :showButton="false"
    ></Breadcrumb>
    <el-form>
      <el-form-item label="关键字" prop="name">
        <el-input
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px"
          v-model="keyword"
          @change="getKeyword"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button
          >
        </el-input>
      </el-form-item>
    </el-form>
    <div style="display: flex; justify-content:space-between; margin-bottom: 10px">
      <el-upload
        class="upload-demo"
        ref="upload"
        action="/api/baogao/importExcel"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :file-list="fileList"
        :auto-upload="false"
        :headers="headers"
      >
        <el-button slot="trigger" size="small" type="primary"
          >选取文件</el-button
        >
        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="submitUpload"
          >导入</el-button
        >
      </el-upload>
      <el-button @click="add">添加</el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="rigan" label="日干"> </el-table-column>
      <el-table-column prop="nianzhi" label="年支"> </el-table-column>
      <el-table-column prop="yuezhi" label="月支"> </el-table-column>
      <el-table-column prop="rizhi" label="日支"> </el-table-column>
      <el-table-column label="性别">
        <template slot-scope="scope">
          <div v-if="scope.row.sex == '1'">男</div>
          <div v-if="scope.row.sex == '0'">女</div>
        </template>
      </el-table-column>
      <el-table-column label="添加时间">
        <template slot-scope="scope">
          {{ scope.row.createdAt | formatDate("yyyy-M-d H:m:s") }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="deleteRow(scope.$index, tableData)"
            type="text"
            size="small"
          >
            移除
          </el-button>
          <el-button
            @click.native.prevent="edit(scope.$index)"
            type="text"
            size="small"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
    <el-dialog title="详细信息" :visible.sync="updatePageVisible" width="80%">
      <updatePage :id="editId" @closeUpdatePate="closeUpdatePate"></updatePage>
    </el-dialog>
    <el-dialog title="添加" :visible.sync="addPageVisible" width="80%">
      <addPage @closeAddPate="closeAddPate"></addPage>
    </el-dialog>
  </div>
</template>
  
  <script>
import operate from "../../api/api.js";
import updatePage from "./edit.vue";
import addPage from "./add.vue";
export default {
  components: {
    updatePage,
    addPage,
  },
  data() {
    return {
      loading: false,
      keyword: "",
      total: 0,
      updatePageVisible: false,
      addPageVisible: false,
      editId: 0,
      tableData: [],
      fileList:[],
      crumb: ["充值产品", "列表"],
      headers: {
        "X-PD-TOKEN": localStorage.getItem("token"),
      }
    };
  },
  methods: {
    submitUpload() {
        this.$refs.upload.submit();
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },
    closeUpdatePate() {
      this.updatePageVisible = false;
    },
    closeAddPate() {
      this.addPageVisible = false;
    },
    deleteRow(index, rows) {
      let _info = this.tableData[index];
      operate.deleteBaogao(_info.id).then((response) => {
        if (response.code == "0000") {
          this.$message({
            message: response.msg,
            type: "success",
            onClose: function () {
              window.location.reload();
            },
          });
        }
      });
    },
    searchput(e) {
      this.getList(1);
    },
    getKeyword() {},
    add() {
      this.addPageVisible = true;
    },
    edit(index) {
      let id = this.tableData[index].id;
      this.editId = id;
      console.log(this.editId);
      this.updatePageVisible = true;
      // let id = this.tableData[index].id;
      // let path = this.$route.path + "/edit/" + id;
      // this.$router.push(path);
    },
    getList(p) {
      let data = {};
      if (this.keyword.length > 0) {
        data = { pageIndex: p, name: this.keyword };
      } else {
        data = { pageIndex: p };
      }
      operate.getBaogaoList(data).then((response) => {
        this.tableData = response.data;
        this.total = response.total;
      });
    },
  },
  mounted() {
    operate.getBaogaoList({ pageIndex: 1 }).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
  },
};
</script>