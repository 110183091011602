import request from "@/utils/request";
const operate = {
    //文章
    addArticle: function (params) {
        return request({
            url: "article/add",
            method: 'put',
            data: params
        });
    },
    deleteArticle: function (id = 0) {
        return request({
            url: "/article/del/" + id,
            method: 'post'
        })
    },
    editArticle: function (params, method) {
        return request({
            url: "article/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getArticleInfo: function (id = 0) {
        return request({
            url: "article/get/" + id,
            method: 'get'
        })
    },
    getArticleList: function (params) {
        return request({
            url: "/article/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
      //礼品
      addGift: function (params) {
        return request({
            url: "gift/add",
            method: 'put',
            data: params
        });
    },
    deleteGift: function (id = 0) {
        return request({
            url: "/gift/del/" + id,
            method: 'post'
        })
    },
    editGift: function (params, method) {
        return request({
            url: "gift/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getGiftInfo: function (id = 0) {
        return request({
            url: "gift/get/" + id,
            method: 'get'
        })
    },
    getGiftList: function (params) {
        return request({
            url: "/gift/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    //轮播
    addLunbo: function (params) {
        return request({
            url: "lunbo/add",
            method: 'put',
            data: params
        });
    },
    deleteLunbo: function (id = 0) {
        return request({
            url: "/lunbo/del/" + id,
            method: 'post'
        })
    },
    editLunbo: function (params, method) {
        return request({
            url: "lunbo/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getLunboInfo: function (id = 0) {
        return request({
            url: "lunbo/get/" + id,
            method: 'get'
        })
    },
    getLunboList: function (params) {
        return request({
            url: "/lunbo/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    //报告
    addBaogao: function (params) {
        return request({
            url: "baogao/add",
            method: 'put',
            data: params
        });
    },
    deleteBaogao: function (id = 0) {
        return request({
            url: "/baogao/del/" + id,
            method: 'post'
        })
    },
    editBaogao: function (params, method) {
        return request({
            url: "baogao/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getBaogaoInfo: function (id = 0) {
        return request({
            url: "baogao/get/" + id,
            method: 'get'
        })
    },
    getBaogaoList: function (params) {
        return request({
            url: "/baogao/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    //老师
    allTeacher: function () {
        return request({
            url: "/teacher/list",
            method: 'get'
        })
    },
    addTeacher: function (params) {
        return request({
            url: "teacher/add",
            method: 'put',
            data: params
        });
    },
    deleteTeacher: function (id = 0) {
        return request({
            url: "/teacher/del/" + id,
            method: 'post'
        })
    },
    editTeacher: function (params, method) {
        return request({
            url: "teacher/edit",
            method: method,
            data: params
        })
    },
    getTeacherInfo: function (id = 0) {
        return request({
            url: "teacher/get/" + id,
            method: 'get'
        })
    },
    getTeacherList: function (params) {
        return request({
            url: "/teacher/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    getTeacherStatistics: function (params) {
        return request({
            url: "/teacher/statistics",
            method: 'get',
            data: params,
            params: params
        })
    },
    //合作申请
    addCooperation: function (params) {
        return request({
            url: "cooperation/add",
            method: 'put',
            data: params,
            params: params
        });
    },
    deleteCooperation: function (id = 0) {
        return request({
            url: "/cooperation/del/" + id,
            method: 'post'
        })
    },
    editCooperation: function (params, method) {
        return request({
            url: "cooperation/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getCooperationInfo: function (id = 0) {
        return request({
            url: "cooperation/get/" + id,
            method: 'get'
        })
    },
    getCooperationList: function (params) {
        return request({
            url: "/cooperation/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    getCooperationStatistics: function (params) {
        return request({
            url: "/cooperation/statistics",
            method: 'get',
            data: params,
            params: params
        })
    },
    changeCooperationLevel: function (params) {
        return request({
            url: "/cooperation/statistics",
            method: 'get',
            data: params,
            params: params
        })
    },
    //管理员
    addManager: function (params) {
        return request({
            url: "manager/add",
            method: 'put',
            data: params,
            params: params
        });
    },
    deleteManager: function (id = 0) {
        return request({
            url: "/manager/del/" + id,
            method: 'post'
        })
    },
    editManager: function (params, method) {
        return request({
            url: "manager/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getManagerInfo: function (id = 0) {
        return request({
            url: "manager/get/" + id,
            method: 'get'
        })
    },
    getManagerList: function (params) {
        return request({
            url: "/manager/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    //订单
    getOrderList: function (params) {
        return request({
            url: "/order/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    deleteorder: function (id = 0) {
        return request({
            url: "/order/del/" + id,
            method: 'post'
        })
    },
    //配置
    getConfigList: function (params) {
        return request({
            url: "/config/list",
            method: 'get',
            data: params,
            params: params
        })
    },
    editConfig: function (params, method) {
        return request({
            url: "/config/batch/save",
            method: 'put',
            data: params
        })
    },
    //基础知识
    addBasicknowledge: function (params) {
        return request({
            url: "basicKnowledge/add",
            method: 'put',
            data: params
        });
    },
    deleteBasicknowledge: function (id = 0) {
        return request({
            url: "/basicKnowledge/del/" + id,
            method: 'post'
        })
    },
    editBasicknowledge: function (params, method) {
        return request({
            url: "basicKnowledge/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getBasicknowledgeInfo: function (id = 0) {
        return request({
            url: "basicKnowledge/get/" + id,
            method: 'get'
        })
    },
    getBasicknowledgeList: function (params) {
        return request({
            url: "/basicKnowledge/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    getBasicknowledgeStatistics: function (params) {
        return request({
            url: "/basicKnowledge/statistics",
            method: 'get',
            data: params,
            params: params
        })
    },
    //进阶学习
    addCourse: function (params) {
        return request({
            url: "course/add",
            method: 'put',
            data: params,
        });
    },
    deleteCourse: function (id = 0) {
        return request({
            url: "/course/del/" + id,
            method: 'post'
        })
    },
    editCourse: function (params, method) {
        return request({
            url: "course/edit",
            method: method,
            data: params,
        })
    },
    getCourseInfo: function (id = 0) {
        return request({
            url: "course/get/" + id,
            method: 'get'
        })
    },
    getCourseList: function (params) {
        return request({
            url: "/course/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    getCourseStatistics: function (params) {
        return request({
            url: "/course/statistics",
            method: 'get',
            data: params,
            params: params
        })
    },
    //用户消费记录
    getUserExpendList: function (params) {
        return request({
            url: "/userExpend/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    deleteUserExpend: function (id = 0) {
        return request({
            url: "/userExpend/del/" + id,
            method: 'post'
        })
    },
    getUserExpendInfo: function (id = 0) {
        return request({
            url: "userExpend/get/" + id,
            method: 'get'
        })
    },
    updateUserrExpendStatus: function (params, method) {
        return request({
            url: "/userExpend/userUpdateStatus",
            method: method,
            data: params,
            params: params
        })
    },
    getUserExpendStatistics: function (params) {
        return request({
            url: "/userExpend/statistics",
            method: 'get',
            data: params,
            params: params
        })
    },
    //用户
    getUserList: function (params) {
        return request({
            url: "/user/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    getUserInfo: function (id = 0) {
        return request({
            url: "user/get/" + id,
            method: 'get'
        })
    },
    editUser: function (params, method) {
        return request({
            url: "user/edit",
            method: method,
            data: params,
            params: params
        })
    },
    deleteUser: function (id = 0) {
        return request({
            url: "/user/del/" + id,
            method: 'post'
        })
    },
    getDailiAll: function (params) {
        return request({
            url: "/cooperation/list",
            method: 'get',
            data: params
        })
    },
    addActivationCodeNum: function (params, method) {
        return request({
            url: "/cooperation/addActivationCodeNum",
            method: method,
            data: params
        })
    },
    decreaseActivationCodeNum:function(params, method){
        return request({
            url: "/cooperation/decreaseActivationCodeNum",
            method: method,
            data: params
        })
    },
    getUserStatistics: function (params) {
        return request({
            url: "/user/statistics",
            method: 'get',
            data: params,
            params: params
        })
    },
    changeCooperationLevel:function(params, method){
        return request({
            url: "/cooperation/editActivationCodePackage",
            method: method,
            data: params
        })
    },///user/changeSuperiorUser
    changeDaili:function(params, method){
        return request({
            url: "/user/changeSuperiorUser",
            method: method,
            data: params
        })
    },// /member/pagingQuery
    getApplyMember:function(params){
        return request({
            url: "/member/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },///member/editStatus
    editApplyMember:function(params, method){
        return request({
            url: "/member/editStatus",
            method: method,
            data: params
        })
    },
    //会员充值
    addProduct: function (params) {
        return request({
            url: "product/add",
            method: 'put',
            data: params,
            params: params
        });
    },
    deleteProduct: function (id = 0) {
        return request({
            url: "/product/del/" + id,
            method: 'post'
        })
    },
    editProduct: function (params, method) {
        return request({
            url: "product/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getProductInfo: function (id = 0) {
        return request({
            url: "product/get/" + id,
            method: 'get'
        })
    },
    getProductList: function (params) {
        return request({
            url: "/product/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    //排盘
    getPaipanList: function (params) {
        return request({
            url: "/paipan/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    deletePaipan: function (id = 0) {
        return request({
            url: "/paipan/del/" + id,
            method: 'post'
        })
    },
    getPaipanInfo: function (id = 0) {
        return request({
            url: "paipan/get/" + id,
            method: 'get'
        })
    },
    //提现
    editWithdrawal: function (params, method) {
        return request({
            url: "userWithdrawal/auditWithdrawal",
            method: method,
            data: params,
            params: params
        })
    },
    getWithdrawalInfo: function (id = 0) {
        return request({
            url: "userWithdrawal/get/" + id,
            method: 'get'
        })
    },
    getWithdrawalList: function (params) {
        return request({
            url: "/userWithdrawal/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    getWithdrawalStatistics: function (params) {
        return request({
            url: "/userWithdrawal/statistics",
            method: 'get',
            data: params,
            params: params
        })
    },
    //用户赠书订单
    deleteUsergift: function (id = 0) {
        return request({
            url: "/userGift/del/" + id,
            method: 'post'
        })
    },
    editUsergift: function (params, method) {
        return request({
            url: "userGift/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getUsergiftInfo: function (id = 0) {
        return request({
            url: "userGift/get/" + id,
            method: 'get'
        })
    },
    getUsergiftList: function (params) {
        return request({
            url: "/userGift/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    //会员申请
    deleteMember: function (id = 0) {
        return request({
            url: "/member/del/" + id,
            method: 'post'
        })
    },
    editMember: function (params, method) {
        return request({
            url: "member/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getMemberInfo: function (id = 0) {
        return request({
            url: "member/get/" + id,
            method: 'get'
        })
    },
    getMemberList: function (params) {
        return request({
            url: "/member/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    //分配
    editTourist: function (params, method) {
        return request({
            url: "/member/distributionTourist",
            method: method,
            data: params,
            params: params
        })
    },
    //上传文件
    uploadFile: function (params, method) {
        return request({
            url: "fileInfo/upload",
            method: method,
            data: params,
            params: params
        })
    },
    //推荐
    delTuijian:function(params,method){
        return request({
            url: "/tuijian/deleteByType",
            method: method,
            data: params,
            params: params
        })
    },
    addTuijian: function (params) {
        return request({
            url: "tuijian/add",
            method: 'put',
            data: params
        });
    },
    deleteTuijian: function (id = 0) {
        return request({
            url: "/tuijian/del/" + id,
            method: 'post'
        })
    },
    getTuijianList: function (params) {
        return request({
            url: "/tuijian/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
     //激活码套餐
     addCodePackage: function (params) {
        return request({
            url: "activationCodePackage/add",
            method: 'put',
            data: params
        });
    },
    editCodePackage: function (params, method) {
        return request({
            url: "activationCodePackage/edit",
            method: method,
            data: params,
            params: params
        })
    },
    deleteCodePackage: function (id = 0) {
        return request({
            url: "/activationCodePackage/del/" + id,
            method: 'post'
        })
    },
    getCodePackageList: function (params) {
        return request({
            url: "/activationCodePackage/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    allCodePackageList: function () {
        return request({
            url: "/activationCodePackage/list",
            method: 'get'
        })
    },
    getCodePackageInfo: function (id = 0) {
        return request({
            url: "activationCodePackage/get/" + id,
            method: 'get'
        })
    },
     //合婚
     getHehunList: function (params) {
        return request({
            url: "/hehunBaogao/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    getHehunInfo: function (id = 0) {
        return request({
            url: "hehunBaogao/get/" + id,
            method: 'get'
        })
    },
     //合婚后天
     addHehunHoutian: function (params) {
        return request({
            url: "hehunHoutian/add",
            method: 'put',
            data: params
        });
    },
    deleteHehunHoutian: function (id = 0) {
        return request({
            url: "/hehunHoutian/del/" + id,
            method: 'post'
        })
    },
    editHehunHoutian: function (params, method) {
        return request({
            url: "hehunHoutian/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getHehunHoutianInfo: function (id = 0) {
        return request({
            url: "hehunHoutian/get/" + id,
            method: 'get'
        })
    },
    getHehunHoutianList: function (params) {
        return request({
            url: "/hehunHoutian/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
//合婚生态
    addHehunShengtai: function (params) {
        return request({
            url: "hehunShengtai/add",
            method: 'put',
            data: params
        });
    },
    deleteHehunShengtai: function (id = 0) {
        return request({
            url: "/hehunShengtai/del/" + id,
            method: 'post'
        })
    },
    editHehunShengtai: function (params, method) {
        return request({
            url: "hehunShengtai/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getHehunShengtaiInfo: function (id = 0) {
        return request({
            url: "hehunShengtai/get/" + id,
            method: 'get'
        })
    },
    getHehunShengtaiList: function (params) {
        return request({
            url: "/hehunShengtai/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
	//合婚生肖
    addHehunShengXiao: function (params) {
        return request({
            url: "hehunShengxiao/add",
            method: 'put',
            data: params
        });
    },
    deleteaddHehunShengXiao: function (id = 0) {
        return request({
            url: "/hehunShengxiao/del/" + id,
            method: 'post'
        })
    },
    editaddHehunShengXiao: function (params, method) {
        return request({
            url: "hehunShengxiao/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getHehunShengXiaoInfo: function (id = 0) {
        return request({
            url: "hehunShengxiao/get/" + id,
            method: 'get'
        })
    },
    getHehunShengXiaoList: function (params) {
        return request({
            url: "/hehunShengxiao/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
		//合婚先天
    addHehunXianTian: function (params) {
        return request({
            url: "hehunXiantian/add",
            method: 'put',
            data: params
        });
    },
    deleteHehunXianTian: function (id = 0) {
        return request({
            url: "/hehunXiantian/del/" + id,
            method: 'post'
        })
    },
    editHehunXianTian: function (params, method) {
        return request({
            url: "hehunXiantian/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getHehunXianTianInfo: function (id = 0) {
        return request({
            url: "hehunXiantian/get/" + id,
            method: 'get'
        })
    },
    getHehunXianTianList: function (params) {
        return request({
            url: "/hehunXiantian/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    	//十天干
        addShiTiangan: function (params) {
            return request({
                url: "shiTiangan/add",
                method: 'put',
                data: params
            });
        },
        deleteShiTiangan: function (id = 0) {
            return request({
                url: "/shiTiangan/del/" + id,
                method: 'post'
            })
        },
        editShiTiangan: function (params, method) {
            return request({
                url: "shiTiangan/edit",
                method: method,
                data: params,
                params: params
            })
        },
        getShiTianganInfo: function (id = 0) {
            return request({
                url: "shiTiangan/get/" + id,
                method: 'get'
            })
        },
        getShiTianganList: function (params) {
            return request({
                url: "/shiTiangan/pagingQuery",
                method: 'get',
                data: params,
                params: params
            })
        },
}
export default operate;