import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '@/views/login/login'
import bd from '@/views/bd/bd'

import notice from '@/views/notice/list'
import noticeedit from '@/views/notice/edit'
import noticeadd from '@/views/notice/add'

import lunbo from '@/views/lunbo/list'
import lunboedit from '@/views/lunbo/edit'
import lunboadd from '@/views/lunbo/add'

import applymember from '@/views/applymember/list'
import applymemberedit from '@/views/applymember/edit'

import gift from '@/views/gift/list'
import giftedit from '@/views/gift/edit'
import giftadd from '@/views/gift/add'

import teacher from '@/views/teacher/list'
import teacheredit from '@/views/teacher/edit'
import teacheradd from '@/views/teacher/add'

import manager from '@/views/manager/list'
import manageredit from '@/views/manager/edit'
import manageradd from '@/views/manager/add'

import cooperation from '@/views/cooperation/list'
import cooperationedit from '@/views/cooperation/edit'

import yuyue from '@/views/yuyue/list'
import yuyueedit from '@/views/yuyue/edit'

import order from '@/views/order/list'

import usergift from '@/views/usergift/list'
import usergiftinfo from '@/views/usergift/info'

import system from '@/views/system/info'

import main from '@/views/main/main'
import basicknowledge from '@/views/basicknowledge/list'
import basicknowledgeedit from '@/views/basicknowledge/edit'
import basicknowledgeadd from '@/views/basicknowledge/add'

import course from '@/views/course/list'
import courseedit from '@/views/course/edit'
import courseadd from '@/views/course/add'

import userexpend from '@/views/userexpend/list'

import user from '@/views/user/list'
import userinfo from '@/views/user/info'

import product from '@/views/product/list'
import productedit from '@/views/product/edit'
import productadd from '@/views/product/add'

import paipan from '@/views/paipan/list'
import paipaninfo from '@/views/paipan/info'

import hehun from '@/views/hehun/list'
import hehuninfo from '@/views/hehun/info'

import hehunhoutian from '@/views/hehunhoutian/list'
import hehunhoutianedit from '@/views/hehunhoutian/edit'
import hehunhoutianadd from '@/views/hehunhoutian/add'

import hehunshengtai from '@/views/hehunshengtai/list'
import hehunshengtaiedit from '@/views/hehunshengtai/edit'
import hehunshengtaiadd from '@/views/hehunshengtai/add'

import hehunshengxiao from '@/views/hehunshengxiao/list'
import hehunshengxiaoedit from '@/views/hehunshengxiao/edit'
import hehunshengxiaoadd from '@/views/hehunshengxiao/add'

import hehunxiantian from '@/views/hehunxiantian/list'
import hehunxiantianedit from '@/views/hehunxiantian/edit'
import hehunxiantianadd from '@/views/hehunxiantian/add'

import shitiangan from '@/views/shitiangan/list'
import shitianganedit from '@/views/shitiangan/edit'
import shitianganadd from '@/views/shitiangan/add'

import withdrawal from '@/views/withdrawal/list'
import withdrawalinfo from '@/views/withdrawal/info'

import baogao from '@/views/baogao/list'
import baogaoedit from '@/views/baogao/edit'
import baogaoadd from '@/views/baogao/add'

import tourist from '@/views/tourist/list'


import codepackage from '@/views/codepackage/list'
import codepackageedit from '@/views/codepackage/edit'
import codepackageadd from '@/views/codepackage/add'

Vue.use(VueRouter)
//创建路由组件，将路由和组件进行映射
const routes = [

    {
        path: '/',
        name: 'login',
        component: login,

    },
    {
        path: '/',
        component: main,
        children: [
            {
                path: '',
                component: bd
            },
            {
                path: '/bd',
                component: bd
            },
            //公告
            {
                path: '/notice',
                component: notice
            },
            {
                path: '/notice/edit/:id',
                component: noticeedit
            },
            {
                path: '/notice/edit/',
                component: noticeedit
            },
            {
                path: '/notice/add',
                component: noticeadd
            },
            //轮播
            {
                path: '/lunbo',
                component: lunbo
            },
            {
                path: '/lunbo/edit/:id',
                component: lunboedit
            },
            {
                path: '/lunbo/edit/',
                component: lunboedit
            },
            {
                path: '/lunbo/add',
                component: lunboadd
            },
            {
                path: '/hehunhoutian',
                component: hehunhoutian
            },
            {
                path: '/hehunhoutian/edit/:id',
                component: hehunhoutianedit
            },
            {
                path: '/hehunhoutian/edit/',
                component: hehunhoutianedit
            },
            {
                path: '/hehunhoutian/add',
                component: hehunhoutianadd
            },
            {
                path: '/hehunshengtai',
                component: hehunshengtai
            },
            {
                path: '/hehunshengtai/edit/:id',
                component: hehunshengtaiedit
            },
            {
                path: '/hehunshengtai/edit/',
                component: hehunshengtaiedit
            },
            {
                path: '/hehunshengtai/add',
                component: hehunshengtaiadd
            },
            {
                path: '/hehunshengxiao',
                component: hehunshengxiao
            },
            {
                path: '/hehunshengxiao/edit/:id',
                component: hehunshengxiaoedit
            },
            {
                path: '/hehunshengxiao/edit/',
                component: hehunshengxiaoedit
            },
            {
                path: '/hehunshengxiao/add',
                component: hehunshengxiaoadd
            },
            {
                path: '/hehunxiantian',
                component: hehunxiantian
            },
            {
                path: '/hehunxiantian/edit/:id',
                component: hehunxiantianedit
            },
            {
                path: '/hehunxiantian/edit/',
                component: hehunxiantianedit
            },
            {
                path: '/hehunxiantian/add',
                component: hehunxiantianadd
            },
            //十天干
            {
                path: '/shitiangan',
                component: shitiangan
            },
            {
                path: '/shitiangan/edit/:id',
                component: shitianganedit
            },
            {
                path: '/shitiangan/edit/',
                component: shitianganedit
            },
            {
                path: '/shitiangan/add',
                component: shitianganadd
            },
             //礼品
            {
                path: '/gift',
                component: gift
            },
            {
                path: '/gift/edit/:id',
                component: giftedit
            },
            {
                path: '/gift/edit/',
                component: giftedit
            },
            {
                path: '/gift/add',
                component: giftadd
            },
            //激活码等级
            {
                path: '/codepackage',
                component: codepackage
            },
            {
                path: '/codepackage/edit/:id',
                component: codepackageedit
            },
            {
                path: '/codepackage/edit/',
                component: codepackageedit
            },
            {
                path: '/codepackage/add',
                component: codepackageadd
            },
            //报告
            {
                path: '/baogao',
                component: baogao
            },
            {
                path: '/baogao/edit/:id',
                component: baogaoedit
            },
            {
                path: '/baogao/edit/',
                component: baogaoedit
            },
            {
                path: '/baogao/add',
                component: baogaoadd
            },
            // 老师
            {
                path: '/teacher',
                component: teacher
            },
            {
                path: '/teacher/edit/:id',
                component: teacheredit
            },
            {
                path: '/teacher/edit/',
                component: teacheredit
            },
            {
                path: '/teacher/add',
                component: teacheradd
            },
            //管理员
            {
                path: '/manager',
                component: manager
            },
            {
                path: '/manager/edit/:id',
                component: manageredit
            },
            {
                path: '/manager/edit/',
                component: manageredit
            },
            {
                path: '/manager/add',
                component: manageradd
            },
            //申请合作
            {
                path: '/cooperation',
                component: cooperation
            },
            {
                path: '/cooperation/edit/:id',
                component: cooperationedit
            },
            {
                path: '/cooperation/edit/',
                component: cooperationedit
            },
            //代理
            {
                path: '/yuyue',
                component: yuyue
            },
            {
                path: '/yuyue/edit/:id',
                component: yuyueedit
            },

            //订单
            {
                path: '/order',
                component: order
            },
            //赠书订单
            {
                path: '/usergift',
                component: usergift
            },
            {
                path: '/usergift/info/:id',
                component: usergiftinfo
            },
            //系统设置
            {
                path: '/system',
                component: system
            },
            //基础知识
            {
                path: '/basicknowledge',
                component: basicknowledge
            },
            {
                path: '/basicknowledge/edit/:id',
                component: basicknowledgeedit
            },
            {
                path: '/basicknowledge/edit/',
                component: basicknowledgeedit
            },
            {
                path: '/basicknowledge/add',
                component: basicknowledgeadd
            },
            //进阶学习
            {
                path: '/course',
                component: course
            },
            {
                path: '/course/edit/:id',
                component: courseedit
            },
            {
                path: '/course/edit/',
                component: courseedit
            },
            {
                path: '/course/add',
                component: courseadd
            },
            //消费记录
            {
                path: '/userexpend',
                component: userexpend
            },
            //排盘
            {
                path: '/paipan',
                component: paipan
            },
            {
                path: '/paipan/info/:id',
                component: paipaninfo
            },
            //合婚
            {
                path: '/hehun',
                component: hehun
            },
            {
                path: '/hehun/info/:id',
                component: hehuninfo
            },
            //用户管理
            {
                path: '/user',
                component: user
            },
            {
                path: '/user/info/:id',
                component: userinfo
            },
            //会员申请
            {
                path: '/applymember',
                component: applymember
            },
            {
                path: '/course/edit/:id',
                component: applymemberedit
            },
            //充值产品
            {
                path: '/product',
                component: product
            },
            {
                path: '/product/edit/:id',
                component: productedit
            },
            {
                path: '/product/edit/',
                component: productedit
            },
            {
                path: '/product/add',
                component: productadd
            },
            //用户提现
            {
                path: '/withdrawal',
                component: withdrawal
            },
            {
                path: '/user/info/:id',
                component: withdrawalinfo
            },
            //游客管理
            {
                path: '/tourist',
                component: tourist
            },
        ]
    },


]
//  创建 router 实例，然后传 `routes` 配置
const router = new VueRouter({
    routes,// (缩写) 相当于 routes: routes,
    mode: 'hash'
})
export default router