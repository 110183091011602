<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="ruleForm.title"></el-input>
      </el-form-item>
      <el-form-item label="老师">
        <el-select v-model="ruleForm.teacherId" placeholder="请选择">
          <el-option
            v-for="item in teachers"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="作者" prop="author">
        <el-input v-model="ruleForm.author"></el-input>
      </el-form-item>
      <el-form-item label="封面图">
        <el-upload
          class="avatar-uploader"
          action="/api/fileInfo/upload"
          :show-file-list="false"
          :on-remove="uploadRemove"
          :on-success="uploadCallBack"
          :headers="headers"
        >
          <img v-if="ruleForm.image" :src="'/api'+ruleForm.image" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio v-model="ruleForm.sex" label="0">男</el-radio>
        <el-radio v-model="ruleForm.sex" label="1">女</el-radio>
      </el-form-item>
      <el-form-item label="简介">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.shotDesc"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="详细简介">
        <quill-editor
          class="editor"
          v-model="ruleForm.description"
          ref="descriptionEditorAdd"
          :options="editorOption"
          @change="onDescriptionChange($event)"
        >
        </quill-editor>
      </el-form-item>
      <el-form-item label="适合人群">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.crowd"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="课程安排">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.course"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="非会员价" prop="charge">
        <el-input v-model="ruleForm.charge"></el-input>
      </el-form-item>
      <el-form-item label="会员价" prop="memberCharge">
        <el-input v-model="ruleForm.memberCharge"></el-input>
      </el-form-item>
      <el-form-item label="是否开启">
        <el-radio-group v-model="ruleForm.status">
        <el-radio :label="0">不开启</el-radio>
        <el-radio :label="1">开启</el-radio>
      </el-radio-group>
      </el-form-item>
      <el-form-item label="是否限时免费">
        <el-radio-group v-model="ruleForm.isfree">
          <el-radio :label="0">不免费</el-radio>
          <el-radio :label="1">免费</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="ruleForm.isfree">
        <el-form-item label="免费开始时间">
          <el-date-picker v-model="ruleForm.freeStartAt" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="免费结束时间">
          <el-date-picker v-model="ruleForm.freeStartEnd" type="date" value-format="yyyy-MM-dd" placeholder="请选择开时间">
          </el-date-picker>
        </el-form-item>
      </div>
      <el-upload
        class="rich-upload"
        action="/api/fileInfo/upload"
        :multiple="false"
        :show-file-list="false"
        :on-success="richUploadSuccess"
        :headers="headers"
        style="height: 10px"
      ></el-upload>
      <el-form-item>
        <el-button type="primary" @click="submitForm">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    //界面组件引用
    quillEditor,
  },
  data() {
    return {
      ruleForm: {
        isfree: 0,
        freeStartAt: '',
        freeStartEnd: '',
        title: "",
        author:'',
        teacherId:'',
        image: "",
        sex: "",
        image: "",
        shotDesc: "",
        description: "",
        crowd: "",
        course: "",
        charge: "",
        memberCharge: "",
        status:1,
        istuijian:0
      },
      teachers:[],
      editorOption: {
        placeholder: "请输入",
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
              ["blockquote", "code-block"], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
              [{ script: "sub" }, { script: "super" }], // 上标/下标
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              // [{ direction: 'rtl' }], // 文本方向
              [{ size: ["small", false, "large", "huge"] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              // [{ font: ['songti'] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ["clean"], // 清除文本格式
              ["image"], // 链接、图片，需要视频的可以加上video
            ],
            handlers: {
              //此处是图片上传时候需要使用到的
              image: function (value) {
                console.log("value", value);
                if (value) {
                  // 点击图片
                  document.querySelector(".rich-upload input").click();
                }
              },
            },
          },
        },
      },
      headers: {
          'X-PD-TOKEN' : localStorage.getItem('token')
      },
    };
  },
  methods: {
    onChargeDescChange({ quill, html, text }) {
      this.chargeDesc = html;
    },
    onDescriptionChange({ quill, html, text }){
      this.description=html
    },
    submitForm() {
      for (let i in this.ruleForm) {
        if (this.ruleForm[i] == 'freeStartAt' || this.ruleForm[i] == 'freeStartEnd') {
          if (this.ruleForm.isfree == 1) {
            if (this.ruleForm[i].length <= 0) {
              this.$message({
                message: "请完成所有内容",
                type: "error",
              });
              return;
            }
          }
        } else {
          if (this.ruleForm[i].length <= 0) {
            this.$message({
              message: "请完成所有内容",
              type: "error",
            });
            return;
          }
        }

      }
      if(this.ruleForm.isfree == 0){
          this.ruleForm.freeStartAt=''
          this.ruleForm.freeStartEnd=''
      }else{
        this.ruleForm.freeStartAt=new Date(this.ruleForm.freeStartAt).getTime()
         this.ruleForm.freeEndAt = new Date(this.ruleForm.freeEndAt).getTime()
      }
      operate.addCourse(this.ruleForm).then((res) => {
        if (res.code == "0000") {
          this.$message.success("成功");
          setTimeout(() => {
            this.$emit("closeAddPate");
          }, 2000);
        }
      });
    },
    uploadRemove(file, fileList) {
      this.ruleForm.image = "";
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == "0000") {
        this.ruleForm.image = response.data;
      }
    },
    //详细描述图片上传
    richUploadSuccess(response, file, fileList) {
      if (response.code == "0000") {
        let quill = this.$refs.descriptionEditorAdd.quill;
        let _image = response.data;
        if (_image) {
          // 获取光标所在位置
          console.log('quill.getSelection().index',quill.getSelection())
          if(!quill.getSelection()||quill.getSelection()==null){
            quill = this.$refs.descriptionEditorAdd.quill;
          }
          let length = quill.getSelection().index;
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, "image", '/api'+_image);
          // 调整光标到最后
          quill.setSelection(length + 1);
        } else {
          // 提示信息，需引入Message
          this.$message.error("图片插入失败！");
        }
      }
    },
  },
  mounted() {
    operate.allTeacher().then((res) => {
      this.teachers=res.data
    });
  },
};
</script>
