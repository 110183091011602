<template>
  <el-menu
    default-active="1-4-1"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    :collapse="isCollapse"
    text-color="#444"
    active-text-color="#409eff"
  style="background-color: #F2F6FF !important;border-bottom: 1px solid #dcdfe6;"
  >
  <h3 v-if="!isCollapse">后台管理</h3>
    <el-menu-item @click="clickmenu(item)" v-for="item in noChildren" :key="item.name" :index="item.name">
      <i :class="item.icon"></i>
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>
    <el-submenu v-for="itemhasChildren in hasChildren" :key="itemhasChildren.name" :index="itemhasChildren.name">
      <template slot="title" v-if="itemhasChildren.icon">
        <i :class="itemhasChildren.icon"></i>
        <span slot="title">{{ itemhasChildren.label }}</span>
      </template>
      <el-menu-item-group v-for="itemchildren in itemhasChildren.children" :key="itemchildren.name" :index="itemchildren.name">
        <el-menu-item @click="clickmenu(itemchildren)" :index="itemchildren.path">{{ itemchildren.label }}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>


<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
.el-menu-vertical-demo{
  height: 100%;
  /* width: 200px; */
}
h3{
  padding: 20px;
    text-align: center;
}
.el-menu-item, .el-submenu__title {
    background-color: #F2F6FF !important;
}
.el-menu-item-group{
  background-color: #F2F6FF !important;
}
.el-submenu .el-menu-item{
  margin:0px !important;
}
</style>

<script>
import operate from "../api/api.js";
export default {
  data() {
    return {
      menuData: [
        {
          path: "/bd",
          name: "home",
          label: "首页",
          icon: "el-icon-s-home"
        },
        {
          name: "basic",
          label: "基础信息",
          icon:"el-icon-s-order",
          children: [
            {
              path: "/lunbo",
              name: "lunbo",
              label: "轮播图"
            },
            {
              path: "/notice",
              name: "notice",
              label: "公告"
            },
            {
              path: "/product",
              name: "product",
              label: "充值产品"
            },
            {
              path: "/codepackage",
              name: "codepackage",
              label: "激活码等级"
            },
            {
              path: "/basicknowledge",
              name: "basicknowledge",
              label: "基础知识"
            },
            {
              path: "/course",
              name: "course",
              label: "进阶学习"
            },
            {
              path: "/baogao",
              name: "baogao",
              label: "报告"
            },
            {
              path: "/shitiangan",
              name: "shitiangan",
              label: "十天干"
            },
            {
              path: "/gift",
              name: "gift",
              label: "礼品"
            },
          ]
        },
        {
          name: "teacher",
          label: "老师管理",
          icon: "el-icon-s-data",
          children: [
            {
              path: "/teacher",
              name: "teacher",
              label: "老师管理"
            },
            {
              path: "/yuyue",
              name: "yuyue",
              label: "预约记录"
            }
          ]
        },

        {
          name: "cooperation",
          label: "代理管理",
          icon: "el-icon-s-data",
          children: [
            {
              path: "/cooperation",
              name: "cooperation",
              label: "代理管理"
            },
            {
              path: "/withdrawal",
              name: "withdrawal",
              label: "提现管理"
            }
          ]
        },
        {
          name: "tourist",
          label: "游客管理",
          icon: "el-icon-s-data",
          children: [
          {
              path: "/tourist",
              name: "tourist",
              label: "游客管理"
            },
          ]
        },
        {
          name: "user",
          label: "用户管理",
          icon: "el-icon-s-data",
          children: [
            {
              path: "/user",
              name: "user",
              label: "用户管理"
            },
            {
              path: "/applymember",
              name: "applymember",
              label: "会员申请"
            },
            {
              path: "/userexpend",
              name: "userexpend",
              label: "消费记录"
            },
            {
              path: "/paipan",
              name: "paipan",
              label: "排盘解密"
            }
          ]
        },
        {
          name: "hehun",
          label: "合婚管理",
          icon: "el-icon-s-data",
          children: [
            {
              path: "/hehunhoutian",
              name: "hehunhoutian",
              label: "后天能量"
            },
           
            {
              path: "/hehunshengtai",
              name: "hehunshengtai",
              label: "生态能力"
            },
            {
              path: "/hehunshengxiao",
              name: "hehunshengxiao",
              label: "生肖匹配"
            },
			{
              path: "/hehunxiantian",
              name: "hehunxiantian",
              label: "先天能量"
            },
            {
              path: "/hehun",
              name: "hehun",
              label: "合婚报告"
            }
          ]
        },
        {
          name: "ordermanager",
          label: "订单管理",
          icon:"el-icon-s-order",
          children: [
            {
              path: "/order",
              name: "order",
              label: "充值订单"
            },
            {
              path: "/usergift",
              name: "usergift",
              label: "赠书订单"
            }
          ]
        },
        {
          label: "系统设置",
          name:"sysset",
          icon: "el-icon-s-tools",
          children: [
            {
              path: "/manager",
              name: "ss",
              label: "管理员",
              icon: "el-icon-s-custom"
            },
            {
              path: "/system",
              name: "system",
              label: "系统设置",
              icon: "el-icon-s-custom"
            }
          ]
        }
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {

    },
    handleClose(key, keyPath) {

    },
    clickmenu(item){
      if(this.$route.path!==item.path){
        this.$router.push(item.path)
      }

    }
  },
  computed:{
    noChildren(){
      return this.menuData.filter(item=>!item.children)
    },
    hasChildren(){
      return this.menuData.filter(item=>item.children)
    },
    isCollapse(){
      return this.$store.state.tab.isCollapse
    }
  },
  mounted() {
  }
};
</script>
